<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th></vs-th>

        <vs-th sort-key="territory_name">Territory</vs-th>
        <vs-th sort-key="code">Code</vs-th>
        <vs-th sort-key="description">Description</vs-th>
        <vs-th sort-key="target_year">Target Year</vs-th>
        <vs-th sort-key="target_type">Target Type</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="created_by">Created By</vs-th>
        <vs-th sort-key="created_at">Created On</vs-th>
        <vs-th sort-key="updated_by">Updated By</vs-th>
        <vs-th sort-key="updated_at">Updated On</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button
                @click.stop="handleView(data[indextr].id)"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-eye"
                title="View"
                style="margin-right: 5px"
              />
              <vs-button
                v-if="data[indextr].status == 'Waiting to Approve'"
                @click.stop="handleApprove(data[indextr].id)"
                size="small"
                color="success"
                icon-pack="feather"
                icon="icon-check"
                title="Approve"
                style="margin-right: 5px"
              />
              <vs-button
                v-if="data[indextr].status == 'Waiting to Approve'"
                @click.stop="handleReject(data[indextr].id)"
                size="small"
                color="danger"
                icon-pack="feather"
                icon="icon-x"
                title="Reject"
                style="margin-right: 5px"
              />
            </div>
          </vs-td>
          <vs-td :data="data[indextr].territory_code">
            {{
              data[indextr].territory_code + " " + data[indextr].territory_name
            }}
          </vs-td>
          <vs-td :data="data[indextr].code">
            {{ data[indextr].code }}
          </vs-td>
          <vs-td :data="data[indextr].description">
            {{ data[indextr].description }}
          </vs-td>
          <vs-td :data="data[indextr].target_year">
            {{ data[indextr].target_year }}
          </vs-td>

          <vs-td :data="data[indextr].target_type">
            {{ data[indextr].target_type }}
          </vs-td>
          <vs-td :data="data[indextr].status">
            {{ data[indextr].status }}
          </vs-td>
          <vs-td :data="data[indextr].created_by">
            {{ data[indextr].created_by }}
          </vs-td>
          <vs-td :data="data[indextr].created_at">
            {{ utcToLocal(data[indextr].created_at) }}
          </vs-td>
          <vs-td :data="data[indextr].updated_by">
            {{ data[indextr].updated_by }}
          </vs-td>
          <vs-td :data="data[indextr].updated_at">
            {{ utcToLocal(data[indextr].updated_at) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    detail: {
      type: Boolean,
    },
    territories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    status: {
      type: String,
    },
    attributeTypes: {
      type: Array,
    },
    targetTypes: {
      type: Array,
    },
    targetYear: {
      type: Date,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      let territoryIDs = [];
      this.territories.forEach((el) => {
        territoryIDs.push(el.id);
      });

      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/approval/table", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_ids: territoryIDs,
            target_types: this.targetTypes,
            attribute_types: this.attributeTypes,
            status: this.status,
            year: this.targetYear
              ? moment(this.targetYear).format("YYYY")
              : null,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleApprove(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to approve this data",
        accept: this.acceptApprove,
      });
    },
    acceptApprove() {
      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "/" + this.deleteId + "/approve")
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully approved",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleReject(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to reject this data",
        accept: this.acceptReject,
      });
    },
    acceptReject() {
      this.$vs.loading();
      this.$http
        .post(this.baseUrl + "/" + this.deleteId + "/reject")
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully rejected",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },

    handleView(id) {
      console.log(id);
      this.$router.push({
        name: "approval-sales-target-view",
        params: { id: id },
      });
    },
    utcToLocal(val) {
      if (val) {
        return moment(val)
          .add(-7, "h")
          .utc()
          .local()
          .format("DD MMM YYYY HH:mm:ss");
      }
    },
  },
  mounted() {},
  watch: {
    status() {
      this.getData();
    },
    territories() {
      this.getData();
    },
    targetTypes() {
      this.getData();
    },
    attributeTypes() {
      this.getData();
    },
    targetYear() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

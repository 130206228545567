<template>
  <vx-card title="Approval Sales Target">
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          multiple
          :filterable="true"
          :options="optionTerritory"
          v-model="selectedTerritory"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Target Year</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          name="year"
          format="yyyy"
          :inline="false"
          v-model="selectedTargetYear"
          minimum-view="year"
        >
        </datepicker>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Target Type</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select
          :filterable="true"
          :options="optionTargetType"
          v-model="selectedTergetType"
        />
      </div>
    </div>
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Attribute Type</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <v-select multiple :filterable="true" :options="optionAttributeType" v-model="selectedAttributeType" />
      </div>
    </div> -->
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vs-tabs v-model="activeTab">
          <vs-tab label="Waiting to Approve">
            <data-table
              :territories="selectedTerritory"
              :target-types="selectedTergetType"
              :target-year="selectedTargetYear"
              :baseUrl="this.baseUrl"
              :status="'Waiting to Approve'"
            ></data-table>
          </vs-tab>
          <vs-tab label="Approved"
            ><data-table
              :territories="selectedTerritory"
              :target-types="selectedTergetType"
              :target-year="selectedTargetYear"
              :baseUrl="this.baseUrl"
              :status="'Approved'"
            ></data-table>
          </vs-tab>
          <vs-tab label="Rejected"
            ><data-table
              :territories="selectedTerritory"
              :target-types="selectedTergetType"
              :target-year="selectedTargetYear"
              :baseUrl="this.baseUrl"
              :status="'Rejected'"
            ></data-table>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable.vue";
import Datepicker from "vuejs-datepicker";

import vSelect from "vue-select";
export default {
  components: {
    DataTable,
    vSelect,
    Datepicker,
  },
  data() {
    return {
      baseUrl: "/api/sfa/v1/sales-target",
      optionTerritory: [],
      selectedTerritory: [],
      selectedTargetYear: null,
      optionTargetType: ["Monthly"],
      selectedTergetType: [],
      optionAttributeType: [
        "General",
        "Principal",
        "Brand",
        "Product Category",
        "Distribution Channel",
        "Sales Channel",
      ],
      selectedAttributeType: [],
      activeTab: 0,
    };
  },
  methods: {
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
  mounted() {
    this.getTerritory();
  },
  watch: {},
};
</script>
